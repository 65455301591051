<script>
export default {
  name: 'STC-Tools',

  components: {
  },

  data() {
    return {
      currentView: '',
    }
  },

  watch: {
    $route(to) {
      this.currentView = to.name
    },
  },
}
</script> 

<template>
  <v-app>
    <v-main>
      <keep-alive>
        <router-view :key="$route.fullPath"></router-view>
      </keep-alive>
    </v-main>
  </v-app>
</template>

<style scoped>
</style>
<style>
</style>