<script>
import { globalStore } from '../main.js'
import axios from 'axios';

export default {
    name: 'HomeView',

    components: {
    },

    data() {
        return {
            showInEuro: false,

            analysisResults: [],
            analyzing: false,
            showError: false,
            errorMessage: null,

            scrollOptions: {
                duration: 700,
                offset: 20,
                easing: 'easeInOutCubic',
            },

            euroToDollar: { "01.22": 1.13, "02.22": 1.13, "03.22": 1.1, "04.22": 1.08, "05.22": 1.06, "06.22": 1.06, "07.22": 1.02, "08.22": 1.01, "09.22": 0.99, "10.22": 0.98, "11.22": 1.02, "12.22": 1.06, "01.23": 1.08, "02.23": 1.07, "03.23": 1.07, "04.23": 1.1, "05.23": 1.09, "06.23": 1.08, "07.23": 1.11, "08.23": 1.09, "09.23": 1.07, "10.23": 1.06, "11.23": 1.08, "12.23": 1.09, "01.24": 1.09, "02.24": 1.08 },
            lastKnownEuroToDollar: null,
        }
    },

    created() {
        this.lastKnownEuroToDollar = Object.values(this.euroToDollar).slice(-1)
    },

    mounted() {
        this.parseAll()
    },

    computed: {
        priceApi() {
            return globalStore.priceHost
        },
    },

    watch: {
    },

    methods: {
        parseAll() {
            console.debug("Starting analysis")
            this.analyzing = true

            this.parseCoins()

            this.fetchCoinPrices()

            console.debug("Analysis finished")
        },

        parseCoins() {
            const coinMetadata = {
                "cmcId": 10202,
                "coin": "STC-1",
                "coinShort": "STC",
                "coinName": "StarCoin",
                "miners": [
                    {
                        name: "ST-Box",
                        consumption: 61,
                        currentRewardsPerMonth: 1050,
                    }
                ],
                "priceInfo": null,
                "statistics": {
                },
            }
            this.analysisResults.push(coinMetadata)
        },

        async fetchCoinPrices() {
            const idsToFetch = this.analysisResults
                .filter((coin) => coin.cmcId && coin.cmcId > 0)
                .map((coin) => coin.cmcId)
                .filter((item, index, currentSet) => currentSet.indexOf(item) === index)
                .join(",")

            if (!idsToFetch) {
                return
            }
            
            axios
                .get(this.priceApi + '?ids=' + idsToFetch)
                .then((response) => {
                    console.debug("Fetching coin prices response:", response)

                    if (response?.data?.status?.error_code == 0) {
                        this.mergeCoinPrices(response.data.data)
                    } else {
                        console.error("Fetching coin prices error:", response.data.status)
                        this.errorMessage = response.data.status.error_message
                        this.showError = true
                    }
                })
                .catch((err) => {
                    console.error("Fetching coin prices error:", err)
                    this.errorMessage = err.status.error_message
                    this.showError = true
                })
                .finally(() => {
                    this.analyzing = false
                });
        },

        mergeCoinPrices(data) {
            if (!data) {
                console.warn("No data for merging coin prices, cancelling")
            }

            Object.entries(data).forEach(([coinName, priceData]) => {
                console.debug("Processing coin price data:", coinName, priceData)
                const allMetadata = this.analysisResults.filter(coinMetadata => coinMetadata.cmcId == coinName || coinMetadata.coinShort == coinName)

                if (allMetadata) { 
                    let info
                    if (Array.isArray(priceData)) {
                        info = priceData.find(result => result.id == coinName || result.symbol == coinName)
                    } else {
                        info = priceData
                    }

                    if (info) {
                        allMetadata.forEach((metadata) => {
                            metadata.priceInfo = {
                                id: info.id,
                                name: info.name,
                                price: info.quote.USD.price,
                                percent_change_1h: info.quote.USD.percent_change_1h,
                                percent_change_24h: info.quote.USD.percent_change_24h,
                                percent_change_7d: info.quote.USD.percent_change_7d,
                                percent_change_30d: info.quote.USD.percent_change_30d,
                            }
                        })
                    }
                } 
                console.debug("Metadata after adding coin prices:", allMetadata)
            })
        },

        calculateBreakEven(metadata, miner) {
            if (!metadata || !metadata.priceInfo || !miner) {
                return null
            }

            const rewardsInFiat = miner.currentRewardsPerMonth * metadata.priceInfo.price
            const consumptionPerMonth = miner.consumption / 1000 * 24 * 30

            return rewardsInFiat / consumptionPerMonth
        },

        numberFormatter(num, digits) {
            const lookup = [
                { value: 1e0, symbol: "" },
                { value: 1e3, symbol: " k" },
                { value: 1e6, symbol: " M" },
                { value: 1e9, symbol: " B" },
                { value: 1e12, symbol: " T" },
                { value: 1e15, symbol: " Qa" },
            ];
            const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
            const item = lookup.findLast(item => num >= item.value);
            return item ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
        },

        toLocalePrice(price) {
            if (!price) {
                return null
            }
            if (this.showInEuro) {
                price /= this.lastKnownEuroToDollar
            }
            return (!this.showInEuro ? '$' : '') + price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 5, }) + (this.showInEuro ? ' €' : '')
        },
    },
}
</script>

<template>
    <v-container class="pb-md-0 px-md-0 font-size-20" width="100%" light>
        <header class="d-flex my-8 justify-space-between" dark>
            <h1 class="no-wrap">STC tools</h1>
            <div><v-switch v-model="showInEuro" inset color="blue" label="Show in Euro"></v-switch></div>
        </header>

        <main class="my-8">
            <v-alert v-if="showError" class="ma-0 rounded-lg text-wrap" type="error" dense text outlined elevation="0">
                {{ errorMessage }}
            </v-alert>

            <v-sheet class="my-8 py-5 rounded-xl" flat color="var(--color-white-1)" light>
                <div v-for="(metadata, index) in analysisResults" :key="'coin-analysis-' + index">
                    <h3>{{ metadata.coinName }}</h3>
                    <v-sheet class="my-8 pa-5 rounded-xl" flat color="var(--color-aubergine-3)" dark>
                        <div v-for="(miner, index2) in metadata.miners" :key="'coin-' + index + '-miner-' + index2">
                            <v-row class="mt-4 align-center">
                                <v-col cols="2"><h4>{{ miner.name }}</h4></v-col>
                                <v-col class="align-center" cols="3"><v-icon size="22" color="green">{{ showInEuro ? 'mdi-currency-eur' : 'mdi-currency-usd' }}</v-icon> Current price</v-col>
                                <v-col class="align-center" cols="3"><v-icon size="22" color="green">mdi-lightning-bolt</v-icon> Power consumption</v-col>
                                <v-col class="align-center" cols="4"><v-icon size="22" color="green">mdi-calculator</v-icon> Break even electricity cost</v-col>
                            </v-row>
                            <v-row class="mb-8 align-center">
                                <v-col cols="2"></v-col>
                                <v-col cols="3">
                                    <v-progress-circular v-if="analyzing" indeterminate color="blue"></v-progress-circular>
                                    <div v-else>{{ metadata.priceInfo?.price ? toLocalePrice(metadata.priceInfo.price) : 'no price info' }}</div>
                                </v-col>
                                <v-col cols="3">61 W</v-col>
                                <v-col cols="4">
                                    <v-progress-circular v-if="analyzing" indeterminate color="blue"></v-progress-circular>
                                    <div v-else>{{ toLocalePrice(calculateBreakEven(metadata, miner)) ?? '-' }}</div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-sheet>
                    <h4>Recent price changes</h4>
                    <v-row v-if="!analyzing" class="mt-4 align-center">
                        <v-col cols="2" :class="metadata.priceInfo.percent_change_1h < 0 ? 'font-red' : 'font-green'">
                            <v-icon class="ma-0 mr-n1" :color="metadata.priceInfo.percent_change_1h < 0 ? 'red' : 'green'" size="22">{{ metadata.priceInfo.percent_change_1h < 0 ? 'mdi-menu-down' : 'mdi-menu-up' }}</v-icon> {{ metadata.priceInfo.percent_change_1h.toFixed(2) }}% (1h)
                        </v-col>
                        <v-col cols="2" :class="metadata.priceInfo.percent_change_24h < 0 ? 'font-red' : 'font-green'">
                            <v-icon class="ma-0 mr-n1" :color="metadata.priceInfo.percent_change_24h < 0 ? 'red' : 'green'" size="22">{{ metadata.priceInfo.percent_change_24h < 0 ? 'mdi-menu-down' : 'mdi-menu-up' }}</v-icon> {{ metadata.priceInfo.percent_change_24h.toFixed(2) }}% (24h)
                        </v-col>
                        <v-col cols="2" :class="metadata.priceInfo.percent_change_7d < 0 ? 'font-red' : 'font-green'">
                            <v-icon class="ma-0 mr-n1" :color="metadata.priceInfo.percent_change_7d < 0 ? 'red' : 'green'" size="22">{{ metadata.priceInfo.percent_change_7d < 0 ? 'mdi-menu-down' : 'mdi-menu-up' }}</v-icon> {{ metadata.priceInfo.percent_change_7d.toFixed(2) }}% (7d)
                        </v-col>
                        <v-col cols="2" :class="metadata.priceInfo.percent_change_30d < 0 ? 'font-red' : 'font-green'">
                            <v-icon class="ma-0 mr-n1" :color="metadata.priceInfo.percent_change_30d < 0 ? 'red' : 'green'" size="22">{{ metadata.priceInfo.percent_change_30d < 0 ? 'mdi-menu-down' : 'mdi-menu-up' }}</v-icon> {{ metadata.priceInfo.percent_change_30d.toFixed(2) }}% (30d)
                        </v-col>
                    </v-row>
                </div>
            </v-sheet>
        </main>
    </v-container>
</template>

<style>
.no-wrap {
    text-wrap: nowrap;
}
.font-red {
    color: red;
}
.font-green {
    color: green;
}
.font-blue {
    color: blue;
}
</style>